<template>
    <div>
        <v-row>
            <v-col
            cols="6"
            >
                Estudiantes del curso
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="students"
                        :search="search"
                        loading=true
                        :loading-text="loadingText"
                        >
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapMutations} from "vuex"

    export default {
        data(){
            return {
                search: '',
                headers: [
                    {
                        text: 'Nombre completo',
                        value: 'fullname'
                    },
                    {
                        text: 'Correo electrónico',
                        value: 'email'
                    }
                ],
                students: [],
                loadingText: 'Cargando estudiantes ...'
            }
        },
        methods: {
            ...mapMutations(['setLayout']),
            getStudents(){
                let courseid = this.$route.params.courseid

                this.axios.get('/getstudentsbycourse/'+courseid)
                .then( (response) => {
                    console.log(response.data)
                    this.students = response.data.data
                })
                .catch( (error) => {
                    console.log(error)
                })
            }
        },
        created(){
            this.setLayout('main-layout')    
        },
        mounted(){
            this.getStudents()
        }
    }
</script>
