<template lang="html">
    <div></div>
</template>

<script>
import {mapMutations} from "vuex"

export default {
    methods: {
        ...mapMutations(['setLoading'])
    },
    created(){
        this.setLoading(true)
        
        this.$store.dispatch('destroyToken')
        .then(() => {
            this.setLoading(false)
            this.$router.push({ name: 'Login' })
        })
    }
}
</script>