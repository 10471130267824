<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Generación de boletas</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.section.$model"
                                :error-messages="sectionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.bimestre.$model"
                                :error-messages="bimestreErrors"
                                label="Bimestre"
                                :items="bimestres"
                                item-text="name"
                                item-value="id"
                                :loading="loadBimestre"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row
                        justify="center"
                        >
                            <v-col
                            cols="6"
                            sm="6"
                            md="3"
                            >
                                <v-checkbox
                                  v-model="byEmail"
                                  label="Enviar por email "
                                ></v-checkbox>
                            </v-col>
                            <v-col
                            cols="6"
                            sm="6"
                            md="3"
                            >
                                <v-checkbox
                                  v-model="allStudents"
                                  label="Todos los alumnos"
                                  v-show="studentsNoEmail"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            >
                                <v-combobox
                                v-model="$v.student.$model"
                                :error-messages="studentErrors"
                                label="Estudiantes"
                                :items="students"
                                item-text="fullname"
                                item-value="id"
                                required
                                :loading="loadStudents"
                                :disabled="allStudents"
                                v-show="studentsNoEmail"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="loadGenerate"
                                v-if="byEmail"
                                >
                                    Confirmar
                                </v-btn>
                            </div>
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="loadGenerate"
                                v-if="!byEmail"
                                >
                                    Generar boleta(s)
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3" v-show="studentsByEmail">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="students"
                        :search="search"
                        :footer-props="{'items-per-page-options':[-1]}"
                        show-select
                        loading=true
                        :loading-text="loadingText"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="showCourses(item)"
                                >
                                    mdi-bookshelf
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <ErrorHandler
        :notify="error"
        />
    </section>
</template>

<script>
    import {mapMutations,mapState} from 'vuex'
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    import ErrorHandler from '@/components/ErrorHandler.vue'

    export default {
        mixins: [validationMixin],
        data(){
            return {
                ciclo: '',
                items: [
                    {value: 77, text: 'Ciclo 2021'}
                ],
                sedes: [],
                sede: '',
                jornada: '',
                jornadas: [],
                level: '',
                levels: [],
                grade: '',
                grades: [],
                section: null,
                sections: [],
                loadSede: false,
                loadJornada: false,
                loadLevel: false,
                loadGrade: false,
                loadBimestre: false,
                student: '',
                students: [],
                loadStudents: false,
                loadGenerate: false,
                allStudents: false,
                loadingText: 'Cargando estudiantes ...',
                byEmail: false,
                search: '',
                selected: [],
                headers: [
                    {
                        text: 'Carnet',
                        value: 'username'
                    },
                    {
                        text: 'Nombre completo',
                        value: 'fullname'
                    },
                    {
                        text: 'Grado',
                        value: 'grado'
                    }
                ],
                studentsByEmail: false,
                studentsNoEmail: false,
                bimestres: [],
                bimestre: '',
                loadSection: false,
                error: {error: ''}
            }
        },
        validations: {
            ciclo: {required},
            sede: {required},
            jornada: {required},
            level: {required},
            grade: {required},
            section: {required},
            student: {required},
            bimestre: {required}
        },
        computed: {
            ...mapState(['schoolCycle']),
            cicloErrors () {
                const errors = []
                if (!this.$v.ciclo.$dirty) return errors
                !this.$v.ciclo.required && errors.push('Elige el ciclo')
                return errors
            },
            sedeErrors () {
                const errors = []
                if (!this.$v.sede.$dirty) return errors
                !this.$v.sede.required && errors.push('Elige la sede')
                return errors
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.jornada.$dirty) return errors
                !this.$v.jornada.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.level.$dirty) return errors
                !this.$v.level.required && errors.push('Elige el nivel')
                return errors
            },
            gradeErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
                return errors
            },
            sectionErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige sección')
                return errors
            },
            studentErrors () {
                const errors = []
                if (!this.$v.student.$dirty && this.allStudents) return errors
                !this.$v.student.required && !this.allStudents && errors.push('Elige un estudiante')
                return errors
            },
            bimestreErrors () {
                const errors = []
                if (!this.$v.bimestre.$dirty) return errors
                !this.$v.bimestre.required && errors.push('Elige el bimestre')
                return errors
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            getDropdownsData(){
                this.loadSede = true
                this.loadJornada = true
                this.loadLevel = true
                this.loadBimestre = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    this.loadBimestre = false

                    this.sedes = response.data.sedes
                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                    this.bimestres = response.data.bimestres
                })
                .catch( (error) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    this.loadBimestre = false
                    console.log(error)
                })
            },
            getGrades(){
                this.loadGrade = true

                this.axios.get('/getgrades/'+this.level)
                .then( (response) => {
                    this.loadGrade = false

                    this.grades = response.data.grados
                })
                .catch( (error) => {
                    this.loadGrade = false
                    console.log(error)
                })
            },
            submit(){
                this.$v.$touch()

                if(!this.$v.sede.$invalid
                    && !this.$v.jornada.$invalid
                    && !this.$v.level.$invalid
                    && !this.$v.grade.$invalid
                    && !this.$v.section.$invalid
                    && !this.$v.bimestre.$invalid
                ){
                        if(this.byEmail){
                            if(this.selected.length > 0){
                                this.sendByEmail()
                            }else{
                                console.log("debe seleccionar por lo menos un estudiante")
                            }
                        }else{
                            if(!this.allStudents && !this.$v.student.$invalid){
                                this.generate()
                            }else if(this.allStudents){
                                this.generate()
                            }
                        }
                }
            },
            getStudents(){
                this.students = []
                this.loadStudents = true
                this.setLoading(true)

                if(this.byEmail){
                    this.studentsByEmail = true
                }else{
                    this.studentsNoEmail = true
                }

                this.axios.post('/students',{
                    sede: this.sede,
                    jornada: this.jornada,
                    level: this.level,
                    grade: this.grade,
                    section: this.section,
                    cycle: this.schoolCycle
                })
                .then( (response) => {
                    this.loadStudents = false
                    this.setLoading(false)
                    this.students = response.data.data
                    this.selected = response.data.data

                    if(this.students.length <= 0){
                        this.setNotify({'show':true,
                                        'color':'primary',
                                        'message':'No se encontró información',
                                        'time':5000})

                        this.loadingText = 'No se encontró información.'
                    }
                })
                .catch( (error) => {
                    this.loadStudents = false
                    this.setLoading(false)

                    console.log(error)
                })
            },
            generate(){
                let student = (this.student != '') ? this.student.id : ''
                let params =    this.sede +"/"+
                                this.jornada +"/"+
                                this.level +"/"+
                                this.grade +"/"+
                                this.section +"/"+
                                this.bimestre +"/"+
                                this.schoolCycle +"/"+
                                student

                var url = process.env.VUE_APP_PDF_URL + "/boleta/" + params
				window.open(url,'_blank');
            },
            sendByEmail(){
                this.setLoading(true)

                this.axios.post('/sendbyemail',{
                    sedeid: this.sede,
                    jornadaid: this.jornada,
                    levelid: this.level,
                    gradeid: this.grade,
                    sectionid: this.section,
                    students: this.selected,
                    bimestre: this.bimestre,
                    cycle: this.schoolCycle
                })
                .then( (response) => {
                    this.setLoading(false)

                    this.setNotify({
                                    'show':true,
                                    'color':'green',
                                    'message':response.data.msg,
                                    'time':-1,
                                    'showClose':true
                                });
                })
                .catch( (error) => {
                    this.setLoading(false)

                    this.error = {  'show':true,
                                    'color':'warning',
                                    'error': error,
                                    'time':5000
                                }
                })
            },
            getSections(){
                if(this.level != ''){
                    this.loadSection = true

                    let grades = this.grades.filter((grade) => {
                        return grade.id == this.grade
                    });

                    this.sections = grades[0].sections

                    this.loadSection = false
                }
            }
        },
        watch: {
            "allStudents": function () {
                this.student = ''
            },
            "level": function(){
                this.grade = ''
                this.section = ''
                this.student = ''
                this.students = []
            },
            "byEmail": function(){
                if(this.students.length > 0){
                    if(this.byEmail){
                        this.studentsByEmail = true
                        this.studentsNoEmail = false
                    }else{
                        this.studentsNoEmail = true
                        this.studentsByEmail = false
                    }
                }
            },
            "grade": function(){
                if(this.grade != ''){
                    this.section = ''
                    this.sections = []
                    this.students = []
                    this.getSections()
                }
            },
            "section": function(){
                if(this.section != ''){
                    this.student = ''
                    this.students = []
                    this.getStudents()
                }
            },
            "schoolCycle": function() {
                if(this.sede != '' &&
                    this.jornada != '' &&
                    this.level != '' &&
                    this.grade != '' &&
                    this.section != ''){
                    this.getStudents()
                }
            }
        },
        components: {
            ErrorHandler
        },
        created(){
            this.setLayout('main-layout')
            this.getDropdownsData()
        }
    }
</script>
