<template>
    <div>
        <v-row>
            <v-col
            cols="11"
            >
                <h2 class="headline">Calificaciones</h2>
                <h2 class="headline">Estudiante: {{studentname}}</h2>
            </v-col>
            <v-col>
                <v-btn
                exact :to="{name: 'ParentHome'}">
                    Regresar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-data-table
                        :headers="headers"
                        :items="studentcourses"
                        :search="search"
                        :footer-props="{'items-per-page-options':[-1]}"
                        loading=true
                        :mobile-breakpoint="0"
                        :loading-text="loadingText"
                        >
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapMutations} from "vuex"

    export default {
        data(){
            return {
                loadingText: 'Cargando cursos ...',
                headers: [
                    {
                        text: 'Orden',
                        value: 'orden_curso'
                    },
                    {
                        text: 'Nombre del curso',
                        value: 'coursename'
                    },
                    {
                        text: '1er bimestre',
                        value: 'nota1'
                    },
                    {
                        text: '2do bimestre',
                        value: 'nota2'
                    },
                    {
                        text: 'Nivelación',
                        value: 'nivelacion'
                    },
                    {
                        text: '3er bimestre',
                        value: 'nota3'
                    },
                    {
                        text: '4to bimestre',
                        value: 'nota4'
                    },
                    {
                        text: 'Nota final',
                        value: 'promedio'
                    }
                ],
                studentcourses: [],
                search: '',
                studentname: '',
            }
        },
        methods: {
            ...mapMutations(['setLayout','setLoading']),
            getStudentCourses(){
                this.setLoading(true)
                let userid = this.$route.params.userid

                this.axios.get('/studentgrades/'+userid)
                .then( (response) => {
                    this.setLoading(false)
                    console.log(response.data)
                    this.studentcourses = response.data.studentGrades
                    this.studentname = this.studentcourses[0].firstname +' '+ this.studentcourses[0].lastname
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            }
        },
        created(){
            this.setLayout('parent-layout')
        },
        mounted(){
            this.getStudentCourses()
        }
    }
</script>
