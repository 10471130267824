<template>
    <v-row
    justify="center"
    >
        <v-col
        cols="12"
        md="4"
        >
            <v-card>
                <v-card-title>
                    Configurar nueva contraseña
                </v-card-title>
                <v-card-text>
                    <v-form
                    autocomplete="false"
                    >
                        <v-text-field
                        v-model="$v.password.$model"
                        label="Contraseña"
                        type="password"
                        :error-messages="passwordErrors"
                        >
                        </v-text-field>
                        <v-text-field
                        v-model="$v.password_confirmation.$model"
                        label="Repetir ontraseña"
                        type="password"
                        :error-messages="passwordConfirmationErrors"
                        >
                        </v-text-field>

                        <div
                        class="d-flex justify-center"
                        >
                            <div>
                                <v-btn
                                color="primary"
                                @click="submit"
                                :loading="loginLoad"
                                >
                                    Guardar
                                </v-btn>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {mapMutations,mapActions} from "vuex"
import { validationMixin } from 'vuelidate'
import { required,sameAs } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data() {
        return {
            password: '',
            password_confirmation: '',
            loginLoad: false
        }
    },
    validations: {
        password: {
            required,
            // minLength: minLength(6)
        },
        password_confirmation: {
            required,
            sameAsPassword: sameAs('password')
        }
    },
    computed: {
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('Campo requerido')
            return errors
        },
        passwordConfirmationErrors () {
            const errors = []
            if (!this.$v.password_confirmation.$dirty) return errors
            !this.$v.password_confirmation.required && errors.push('Campo requerido')
            !this.$v.password_confirmation.sameAsPassword && errors.push('Contraseña no coincide')
            return errors
        },
    },
    methods: {
        ...mapMutations(['setLayout','setNotify']),
        ...mapActions(['setUser']),
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                this.loginLoad = true
                let userid = this.$route.params.userid

                this.axios.put('/users/passwordupdate/'+userid,{
                    password: this.password,
                    password_confirmation: this.password_confirmation
                })
                .then((response) => {
                    let user = response.data.user
                    this.setUser(user)
					this.loginLoad = false

                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})

                    if(user.name_roles.includes('Padre')){
                        this.$router.push({ name: 'ParentHome' })
                    }else{
                        this.$router.push({ name: 'Home' })
                    }
				})
				.catch((error) => {
					this.loginLoad = false

					console.log(error)
				})
            }
        }
    },
    created(){
        this.setLayout('login-layout')
    }
}
</script>
