<template>
    <div>
        <v-row>
            <v-col
            cols="11"
            md="10"
            >
                <h2 class="headline">Observaciones</h2>
                <h2 class="headline">Estudiante: {{student.fullname}}</h2>
            </v-col>
            <v-col>
                <v-btn
                exact :to="{name: 'ParentHome'}">
                    Regresar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                v-show="showAttendance"
                >
                    <v-card-text>
                        <v-data-table
                        :headers="headers"
                        :items="attendance"
                        >
                            <template v-slot:item.date="{ item }">
                                <td>{{moment(item.date).locale('es').format('dddd DD, MMMM')}}</td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapMutations} from "vuex"

    export default {
        data(){
            return {
                loadingText: 'Cargando cursos ...',
                headers: [
                    {
                        text: 'Fecha',
                        value: 'date'
                    },
                    {
                        text: 'Asistencia',
                        value: 'description'
                    },
                    {
                        text: 'Observaciones',
                        value: 'observaciones'
                    }
                ],
                studentcourses: [],
                student: '',
                userid: null,
                attendance: [],
                showAttendance: false
            }
        },
        computed: {
            studentName() {
                if(this.studentcourses.length > 0)
                    return this.studentcourses[0].firstname +' '+ this.studentcourses[0].lastname
                else
                    return ''
            }
        },
        methods: {
            ...mapMutations(['setLayout','setLoading','setNotify']),
            getAttendance() {
                this.showAttendance = false
                this.setLoading(true)
                let userid = this.$route.params.userid

                this.axios.post('/students/attendance',{
                    studentid: userid,
                    course: this.course
                })
                .then( (response) => {
                    this.setLoading(false)
                    this.student = response.data.student
                    this.attendance = response.data.attendance

                    if(this.attendance.length > 0){
                        this.showAttendance = true
                    }else{
                        this.setNotify({'show':true,
                                        'color':'info',
                                        'message':'No se encontró información',
                                        'time':5000})
                    }
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            }
        },
        created(){
            this.setLayout('parent-layout')
        },
        mounted(){
            this.getAttendance()
        }
    }
</script>
