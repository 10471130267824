<template lang="html">
    <v-container>
        <v-row>
            <v-col
            cols="11"
            md="11"
            >
                <h2 class="headline">Actividades por curso</h2>
                <h2 class="headline">
                    Estudiante: {{student.firstname}} {{student.lastname}}
                </h2>
            </v-col>
            <v-col>
                <v-btn
                exact :to="{name: 'ParentHome'}">
                    Regresar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.bimestre.$model"
                                :error-messages="bimestreErrors"
                                label="Bimestre"
                                :items="bimestres"
                                item-text="name"
                                item-value="id"
                                :loading="loadBimestre"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="submit"
                                >
                                    Generar reporte
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            md="12"
            >
                <v-card
                v-show="showResults"
                >
                    <v-tabs
                    background-color="blue accent-4"
                    dark
                    next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline"
                    show-arrows
                    v-model="tab"
                    >
                        <v-tab
                        v-for="item in tabNames"
                        :key="item.nombre_curso"
                        @click="getData(item)"
                        >
                            {{item.nombre_curso}}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                        v-for="item in tabNames"
                        :key="item.nombre_curso"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <v-data-table
                                    :headers="headers"
                                    :items="tabData"
                                    :search="search"
                                    loading=true
                                    :mobile-breakpoint="0"
                                    :loading-text="loadingText"
                                    >
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data(){
        return {
            tab: null,
            text: 'aqui el contenido',
            tabNames: [],
            tabData: [],
            headers: [
                {
                    text: 'Nombre actividad',
                    value: 'actividad'
                },
                {
                    text: 'Nota',
                    value: 'finalgrade'
                },
                {
                    text: 'Valor actividad',
                    value: 'rawgrademax'
                },
                {
                    text: 'Comentarios',
                    value: 'feedback'
                }
            ],
            search: '',
            activities: [],
            student: '',
            loadingText: '',
            bimestre: '',
            bimestres: [],
            loadBimestre: false,
            studentsHeaders: [
                {
                    text: 'Carnet',
                    value: 'username'
                },
                {
                    text: 'Nombre completo',
                    value: 'fullname'
                },
                {
                    text: 'Grado',
                    value: 'grado'
                }
            ],
        }
    },
    validations: {
        bimestre: {required}
    },
    computed: {
        showResults() {
            return this.tabNames.length > 0 ? true : false
        },
        bimestreErrors () {
            const errors = []
            if (!this.$v.bimestre.$dirty) return errors
            !this.$v.bimestre.required && errors.push('Elige el bimestre')
            return errors
        }
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        submit(){
            this.$v.$touch()

            if(!this.$v.bimestre.$invalid){
                this.getCoursesActivities()
            }
        },
        getStudentById(){
            let userid = this.$route.params.userid

            this.axios.get('/studentbyid/'+userid)
            .then( (response) => {

                this.student = response.data
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        getCoursesActivities(){
            this.tabNames = []
            this.tabData = []
            this.tabNames = []
            this.tabData = []
            this.setLoading(true)

            let userid = this.$route.params.userid

            this.axios.post('/getcourseactivities',{
                bimestre: this.bimestre,
                student: userid,
                cycle: this.moment().format("Y")
            })
            .then( (response) => {
                this.setLoading(false)

                this.tabNames = response.data.courses
                this.activities = response.data.activities

                this.getData(this.tabNames[0])
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        getData(item){
            let activities = this.activities.filter((activity) => {
                return activity.id_curso == item.id_curso
            });

            this.tabData = activities
        },
        getDropdownsData(){
            this.loadSede = true
            this.loadJornada = true
            this.loadLevel = true
            this.loadBimestre = true

            this.axios.get('/getdropdownsdata')
            .then( (response) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false

                this.sedes = response.data.sedes
                this.jornadas = response.data.jornadas
                this.levels = response.data.niveles
                this.bimestres = response.data.bimestres
            })
            .catch( (error) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false
                console.log(error)
            })
        },
    },
    watch: {
    },
    created(){
        this.setLayout('parent-layout')
        this.getStudentById()
        this.getDropdownsData()
    }
}
</script>

<style lang="css" scoped>
</style>
