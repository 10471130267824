var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mx-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',{staticClass:"headline"},[_vm._v("Bitacora de correos enviados")])])],1),_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Tipo","items":_vm.types,"item-text":"name","item-value":"id","error-messages":_vm.typeErrors},model:{value:(_vm.$v.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.type, "$model", $$v)},expression:"$v.type.$model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.startDateErrors,"label":"Fecha inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.$v.startDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.startDate, "$model", $$v)},expression:"$v.startDate.$model"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.$v.startDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.startDate, "$model", $$v)},expression:"$v.startDate.$model"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.endDateErrors,"label":"Fecha fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.$v.endDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.endDate, "$model", $$v)},expression:"$v.endDate.$model"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menub),callback:function ($$v) {_vm.menub=$$v},expression:"menub"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menub = false}},model:{value:(_vm.$v.endDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.endDate, "$model", $$v)},expression:"$v.endDate.$model"}})],1)],1)],1),_c('section',{staticClass:"d-flex justify-center"},[_c('div',[_c('v-btn',{on:{"click":_vm.submit}},[_vm._v(" Consultar ")])],1)])],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmailActivity),expression:"showEmailActivity"}]},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.emailActivity,"search":_vm.search,"loading":"true"},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description == 'boleta email'
                                || item.description == 'boleta email failed')?_c('td',[_vm._v(" Boleta ")]):(item.description == 'activity email'
                                || item.description == 'activity email failed')?_c('td',[_vm._v(" Alerta académica ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
                                var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.moment(item.created_at).format('DD-MM-YYYY h:mm:ss a')))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }