<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Asignación de alumnos en el nuevo ciclo</h2>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row
                        justify="center"
                        >
                            <v-col
                            md="8"
                            class="text-center"
                            >
                                <img
                                :src="require('../../assets/bulk_example.png')"
                                />
                                <div
                                class="mt-1"
                                >
                                    El archivo excel debe contener los datos mostrados en el ejemplo y en ese orden,
                                    el documento no debe contener el encabezado, solo los datos puros.
                                </div>
                            </v-col>
                        </v-row>
                        <v-form>
                            <v-file-input
                            chips
                            show-size
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            label="Seleccionar archivo excel"
                            v-model="$v.excel.$model"
                            :error-messages="excelFileErrors"
                            ></v-file-input>

                            <section class="d-flex justify-center mt-5">
                                <div>
                                    <v-btn
                                    @click="uploadFile"
                                    >
                                        Ejecutar
                                    </v-btn>
                                </div>
                            </section>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data(){
        return {
            excel: []
        }
    },
    validations: {
        excel: {required}
    },
    computed: {
        ...mapState(['schoolCycle']),
        excelFileErrors () {
            const errors = []
            if (!this.$v.excel.$dirty) return errors
            !this.$v.excel.required && errors.push('Archivo requerido')
            return errors
        },
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        uploadFile() {
            this.$v.$touch()

            if(!this.$v.$invalid) {
                this.setLoading(true)

                let formData = new FormData();
                formData.append('excel',this.excel)

                const json = JSON.stringify(this.schoolCycle);
                formData.append('school', json);

                this.axios.post('/students/bulkenrollment',formData)
                .then( (response) => {
                    this.setLoading(false)
                    this.excel = []
                    this.$v.$reset()

                    this.setNotify({'show':true,
                                        'color':'success',
                                        'message': response.data.message,
                                        'time':5000})
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            }
        }
    },
    watch: {
    },
    created(){
        this.setLayout('main-layout')
    }
}
</script>

<style lang="css" scoped>
</style>
