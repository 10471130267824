<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Bitacora de correos enviados</h2>
            </v-col>
        </v-row>
        <v-card
        class="mt-3"
        >
            <v-card-text>
                <v-row>
                    <v-col
                    cols="4"
                    >
                        <v-select
                        label="Tipo"
                        v-model="$v.type.$model"
                        :items="types"
                        item-text="name"
                        item-value="id"
                        :error-messages="typeErrors"
                        ></v-select>
                    </v-col>
                    <v-col
                    cols="4"
                    >
                        <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="$v.startDate.$model"
                                :error-messages="startDateErrors"
                                label="Fecha inicio"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="$v.startDate.$model"
                            @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                    cols="4"
                    >
                        <v-menu
                        v-model="menub"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="$v.endDate.$model"
                                :error-messages="endDateErrors"
                                label="Fecha fin"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="$v.endDate.$model"
                            @input="menub = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <section class="d-flex justify-center">
                    <div>
                        <v-btn
                        @click="submit"
                        >
                            Consultar
                        </v-btn>
                    </div>
                </section>
            </v-card-text>
        </v-card>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showEmailActivity"
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="emailActivity"
                        :search="search"
                        loading=true
                        >
                            <template v-slot:item.description="{ item }">
                                <td
                                v-if="item.description == 'boleta email'
                                    || item.description == 'boleta email failed'"
                                >
                                    Boleta
                                </td>
                                <td v-else-if="item.description == 'activity email'
                                    || item.description == 'activity email failed'"
                                >
                                    Alerta académica
                                </td>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <td>{{moment(item.created_at).format('DD-MM-YYYY h:mm:ss a')}}</td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'Tipo',
                    value: 'description'
                },
                {
                    text: 'Enviado a',
                    value: 'motivo'
                },
                {
                    text: 'Correo',
                    value: 'subject.email_encargado'
                },
                {
                    text: 'Carnet',
                    value: 'carnet_alumno'
                },
                {
                    text: 'Alumno',
                    value: 'nombre_alumno'
                },
                {
                    text: 'Enviado por',
                    value: 'causer.name'
                },
                {
                    text: 'Fecha',
                    value: 'created_at'
                },
            ],
            emailActivity: [],
            type: '',
            types: [
                {id: 'boleta email',name: 'Boletas'},
                {id: 'activity email',name: 'Alertas académicas'}
            ],
            showEmailActivity: false,
            menu: false,
            menub: false,
            startDate: '',
            endDate: ''
        }
    },
    validations: {
        type: {required},
        startDate: {required},
        endDate: {required}
    },
    computed: {
        typeErrors () {
            const errors = []
            if (!this.$v.type.$dirty) return errors
            !this.$v.type.required && errors.push('Campo requerido')
            return errors
        },
        startDateErrors () {
            const errors = []
            if (!this.$v.startDate.$dirty) return errors
            !this.$v.startDate.required && errors.push('Campo requerido')
            return errors
        },
        endDateErrors () {
            const errors = []
            if (!this.$v.endDate.$dirty) return errors
            !this.$v.endDate.required && errors.push('Campo requerido')
            return errors
        }
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                this.get()
            }
        },
        get() {
            this.setLoading(true)
            this.showEmailActivity = false
            this.emailActivity = []

            this.axios.post('/emailactivitylog',{
                type: this.type,
                startDate: this.startDate,
                endDate: this.endDate
            })
            .then((response) => {
                this.setLoading(false)
                this.emailActivity = response.data.emailActivity
                this.showEmailActivity = true

                if(this.emailActivity.length <= 0) {
                    this.showEmailActivity = false
					this.setNotify({'show':true,
							'color':'primary',
							'message':'No se encontró información',
							'time':5000})
                }
            })
            .catch((error) => {
                this.setLoading(false)
                console.log(error)
            })
        }
    },
    created(){
        this.setLayout('main-layout')
    }
}
</script>
