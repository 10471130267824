<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Listado de estudiantes</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado/Carrera"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.section.$model"
                                :error-messages="sectionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="studentsLoad"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showStudents"
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="students"
                        :search="search"
                        loading=true
                        :loading-text="loadingText"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="showCourses(item)"
                                >
                                    mdi-bookshelf
                                </v-icon>
                            </template>

                            <template v-slot:item.actions="{ item }">
                               <v-menu
                               bottom
                               left
                               >
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-btn
                                       icon
                                       v-bind="attrs"
                                       v-on="on"
                                       >
                                           <v-icon>mdi-dots-vertical</v-icon>
                                       </v-btn>
                                   </template>

                                   <v-list>
                                       <v-list-item
                                       @click="showCourses(item)"
                                       >
                                           <v-list-item-title>Cursos</v-list-item-title>
                                       </v-list-item>
                                       <v-list-item
                                       @click="activitiesByCourse(item)"
                                       >
                                           <v-list-item-title>Actividades por curso</v-list-item-title>
                                       </v-list-item>
                                   </v-list>
                               </v-menu>
                           </template>

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>
<script>
    import {mapMutations,mapState,mapActions} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        data(){
            return {
                sedes: [],
                sede: '',
                jornada: '',
                level: '',
                grade: '',
                jornadas: [],
                levels: [],
                grades: [],
                section: null,
                sections: [],
                loadSede: false,
                loadJornada: false,
                loadLevel: false,
                loadGrade: false,
                loadSection: false,
                studentsLoad: false,
                showStudents: false,

                search: '',
                headers: [
                    {
                        text: 'Carnet',
                        value: 'carnet'
                    },
                    {
                        text: 'Nombre completo',
                        value: 'fullname'
                    },
                    {
                        text: 'Correo electrónico',
                        value: 'email'
                    },
                    {
                        text: 'Grado',
                        value: 'grado'
                    },
                    {
                        text: 'Opciones',
                        value: 'actions',
                        sortable: false
                    },
                ],
                students: [],
                loadingText: 'Seleccionar Grado'
            }
        },
        validations: {
            sede: {required},
            jornada: {required},
            level: {required},
            grade: {required},
            section: {required},
        },
        computed: {
            ...mapState(['settedSede',
                        'settedJornada',
                        'settedLevel',
                        'settedGrade',
                        'settedSection',
                        'remember',
                        'schoolCycle']),
            sedeErrors () {
                const errors = []
                if (!this.$v.sede.$dirty) return errors
                !this.$v.sede.required && errors.push('Elige la sede')
                return errors
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.jornada.$dirty) return errors
                !this.$v.jornada.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.level.$dirty) return errors
                !this.$v.level.required && errors.push('Elige el nivel')
                return errors
            },
            gradeErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
                return errors
            },
            sectionErrors () {
                const errors = []
                if (!this.$v.section.$dirty) return errors
                !this.$v.section.required && errors.push('Elige la sección')
                return errors
            },
            studentErrors () {
                const errors = []
                if (!this.$v.student.$dirty && this.allStudents) return errors
                !this.$v.student.required && !this.allStudents && errors.push('Elige un estudiante')
                return errors
            },
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            ...mapActions(['setSelectedFilters','unsetSelectedFilters','unsetRemember']),
            getDropdownsData(){
                this.loadSede = true
                this.loadJornada = true
                this.loadLevel = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false

                    this.sedes = response.data.sedes
                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                })
                .catch( (error) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    console.log(error)
                })
            },
            getGrades(){
                this.loadGrade = true

                this.axios.get('/getgrades/'+this.level)
                .then( (response) => {
                    this.loadGrade = false

                    this.grades = response.data.grados
                })
                .catch( (error) => {
                    this.loadGrade = false
                    console.log(error)
                })
            },
            submit(){
                this.$v.$touch()

                if(!this.$v.$invalid){
                       this.getStudents()
                }
            },
            getStudents(){
                this.students = []
                this.studentsLoad = true
                this.setLoading(true)

                this.axios.post('/students',{
                    sede:  this.sede,
                    jornada: this.jornada,
                    level: this.level,
                    grade: this.grade,
                    section: this.section,
                    cycle: this.schoolCycle
                })
                .then( (response) => {
                    this.studentsLoad = false
                    this.setLoading(false)
                    this.students = response.data.data
                    this.showStudents = true

                    this.setSelectedFilters({
                                                'sede':this.sede,
                                                'jornada':this.jornada,
                                                'level':this.level,
                                                'grade':this.grade,
                                                'section':this.section
                                            })

                    if(this.students.length <= 0){
                        this.showStudents = false
                        this.setNotify({'show':true,
                                        'color':'primary',
                                        'message':'No se encontró información',
                                        'time':5000})

                        this.loadingText = 'No se encontró información.'
                    }

                    if(!this.remember){
                        this.unsetRemember(false)
                    }
                })
                .catch( (error) => {
                    this.studentsLoad = false
                    this.setLoading(false)

                    console.log(error)
                })
            },
            showCourses(item){
                this.$router.push({ name: 'CoursesByStudent', params: { userid: item.id, grade_id: this.grade } })
            },
            activitiesByCourse(item){
                this.$router.push({ name: 'ActivitiesByCourse', params: { userid: item.id } })
            },
            getSections(){
                if(this.level != '' && this.grade != ''){
                    this.loadSection = true

                    let grades = this.grades.filter((grade) => {
                        return grade.id == this.grade
                    });
                    this.sections = grades[0].sections
                    this.loadSection = false
                }
            }
        },
        created(){
            this.getDropdownsData()
            this.setLayout('main-layout')

            if(JSON.parse(this.remember)){
                this.sede = (this.settedSede != null) ? this.settedSede : '',
                this.jornada = (this.settedJornada != null) ? this.settedJornada : '',
                this.level = (this.settedLevel != null) ? this.settedLevel : '',
                this.grade = (this.settedGrade != null) ? this.settedGrade : '',
                this.section = (this.settedSection != null) ? this.settedSection : '',
                this.getStudents()

                this.unsetSelectedFilters()
            }
        },
        watch: {
            "level": function(){
                if(!this.remember){
                    this.grade = ''
                }

                this.getGrades()
            },
            "grade": function(){
                if(this.grade != '' && this.grades.length > 0){
                    this.sections = []
                    this.getSections()
                }
            },
            "grades": function(){
                if(this.remember){
                    this.sections = []
                    this.getSections()
                }
            }
        }
    }
</script>
