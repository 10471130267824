<template>
    <div>
        <v-row>
            <v-col
            cols="12"
            >
                <h2 class="headline">Cursos del estudiante {{studentname}}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-data-table
                        :headers="headers"
                        :items="studentcourses"
                        :search="search"
                        :footer-props="{'items-per-page-options':[-1]}"
                        loading=true
                        :loading-text="loadingText"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="goToCourse(item)"
                                >
                                    mdi-book-arrow-right
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex"

    export default {
        data(){
            return {
                loadingText: 'Cargando cursos ...',
                headers: [
                    {
                        text: 'Orden',
                        value: 'orden_curso'
                    },
                    {
                        text: 'Nombre del curso',
                        value: 'coursename'
                    },
                    {
                        text: '1er bimestre',
                        value: 'nota1'
                    },
                    {
                        text: '2do bimestre',
                        value: 'nota2'
                    },
                    {
                        text: 'Nivelación',
                        value: 'nivelacion'
                    },
                    {
                        text: '3er bimestre',
                        value: 'nota3'
                    },
                    {
                        text: '4to bimestre',
                        value: 'nota4'
                    },
                    {
                        text: 'Nota final',
                        value: 'promedio'
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        sortable: false
                    },
                ],
                studentcourses: [],
                search: '',
                studentname: ''
            }
        },
        computed: {
            ...mapState(['schoolCycle'])
        },
        methods: {
            ...mapMutations(['setLayout','setRemember','setLoading']),
            getStudentCourses(){
                this.setLoading(true)
                let userid = this.$route.params.userid
                let grade = this.$route.params.grade_id

                this.axios.get('/getstudentcourses/'+userid+'/'+this.schoolCycle+'/'+grade)
                .then( (response) => {
                    this.setLoading(false)

                    this.studentcourses = response.data.data
                    this.studentInfo = response.data.studentInfo
                    this.studentname = (this.studentInfo != null) ? this.studentInfo.fullname : this.studentcourses[0].fullname

                    if(this.studentcourses.length <= 0){
                        this.setNotify({'show':true,
                                        'color':'primary',
                                        'message':'No se encontró información',
                                        'time':5000})

                        this.loadingText = 'No se encontró información.'
                    }
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            showActivities(){
                let user_id = this.$route.params.userid
                this.$router.push({ name: 'ActivitiesByCourse',
                                    params: {
                                                userid: user_id
                                            }
                                    })
            },
            goToCourse(item){
                    let url = process.env.VUE_APP_MOODLE_COURSE_URL + item.moodle_course_id
                    console.log(url)
                    window.open(url,'_blank')
            }
        },
        watch: {
            "schoolCycle": function () {
                this.getStudentCourses()
            }
        },
        created(){
            this.setLayout('main-layout')
            this.setRemember(true)
        },
        mounted(){
            this.getStudentCourses()
        }
    }
</script>
