<template lang="html">
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Inscripción</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="2"
                            >
                                <v-select
                                label="Ciclo"
                                :items="schoolCycles"
                                item-value="id"
                                v-model="$v.student.school_cycle.$model"
                                :error-messages="schoolCycleErrors"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-text-field
                                label="Carnet estudiante"
                                v-model="$v.student.carnet_alumno.$model"
                                :error-messages="carnetErrors"
                                :loading="isLoadingCarnet"
                                @keypress.enter="searchStudent"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="7"
                            >
                                <v-text-field
                                label="Nombre estudiante"
                                v-model="student.nombre_alumno"
                                :disabled="dsb"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.student.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                                <v-select
                                v-model="$v.student.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            >
                                <v-select
                                v-model="$v.student.nivel.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.student.grado.$model"
                                :error-messages="gradeErrors"
                                label="Grado"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="1"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="$v.student.seccion.$model"
                                :error-messages="seccionErrors"
                                :loading="loadSection"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="submit"
                                >
                                    Inscribir
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data(){
        return {
            student : {
                id: null,
                enrollment_id: null,
                carnet_alumno: '',
                nombre_alumno: '',
                sede: '',
                jornada: '',
                nivel: '',
                grado: '',
                seccion: '',
                school_cycle: ''
            },
            isLoadingCarnet: false,
            dsb: true,
            sedes: [],
            jornadas: [],
            levels: [],
            grades: [],
            sections: [],
            loadSede: false,
            loadJornada: false,
            loadLevel: false,
            loadGrade: false,
            loadSection: false,
            requested: false
        }
    },
    validations: {
        student: {
            carnet_alumno: {required},
            sede: {required},
            jornada: {required},
            nivel: {required},
            grado: {required},
            seccion: {required},
            school_cycle: {required}
        },
    },
    computed: {
        sedeErrors () {
            const errors = []
            if (!this.$v.student.sede.$dirty) return errors
            !this.$v.student.sede.required && errors.push('Elige la sede')
            return errors
        },
        jornadaErrors () {
            const errors = []
            if (!this.$v.student.jornada.$dirty) return errors
            !this.$v.student.jornada.required && errors.push('Elige la jornada')
            return errors
        },
        nivelErrors () {
            const errors = []
            if (!this.$v.student.nivel.$dirty) return errors
            !this.$v.student.nivel.required && errors.push('Elige el nivel')
            return errors
        },
        gradeErrors () {
            const errors = []
            if (!this.$v.student.grado.$dirty) return errors
            !this.$v.student.grado.required && errors.push('Elige el/la grado/carrera')
            return errors
        },
        carnetErrors () {
            const errors = []
            if (!this.$v.student.carnet_alumno.$dirty) return errors
            !this.$v.student.carnet_alumno.required && errors.push('Campo requerido')
            return errors
        },
        schoolCycleErrors () {
            const errors = []
            if (!this.$v.student.school_cycle.$dirty) return errors
            !this.$v.student.school_cycle.required && errors.push('Campo requerido')
            return errors
        },
        seccionErrors () {
            const errors = []
            if (!this.$v.student.seccion.$dirty) return errors
            !this.$v.student.seccion.required && errors.push('Campo requerido')
            return errors
        },
        schoolCycles() {
            let cycles = Array()
            let firstYear = 2022
            let endYear = this.moment().format("Y")
            endYear = parseInt(endYear)+2

            while(firstYear < endYear) {
                cycles.push(firstYear)
                firstYear++
            }

            return cycles
        }
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        submit(){
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.student.enrollment_id == null){
                    this.enrollStudent()
                }else{
                    this.updateEnrollment()
                }
            }
        },
        enrollStudent() {
            this.setLoading(true)

            this.axios.post('/students/enrollstudent',this.student)
            .then( (response) => {
                this.setLoading(false)
                this.student.id = null

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        updateEnrollment() {
            this.setLoading(true)

            this.axios.put('/students/'+this.student.enrollment_id,this.student)
            .then( (response) => {
                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.student.id = null
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        searchStudent() {
            this.isLoadingCarnet = true
            this.student.id = null

            this.axios.post('/students/searchunsuscribed',{
                carnet: this.student.carnet_alumno,
                cycle: this.student.school_cycle
            })
            .then( (response) => {
                this.isLoadingCarnet = false

                if(response.data.student == null){
                    this.student.id = null

                    this.setNotify({'show':true,
                                'color':'info',
                                'message':"Alumno no encontrado",
                                'time':5000})
                }else{
                    if(response.data.student.student_enrollment.length <= 0){
                        this.student.id = response.data.student.id
                        this.student.nombre_alumno = response.data.student.fullname
                    }else{
                        this.student.id = response.data.student.id
                        this.student.nombre_alumno = response.data.student.fullname
                        this.student.sede = response.data.student.student_enrollment[0].sede_id
                        this.student.jornada = response.data.student.student_enrollment[0].jornada_id
                        this.student.nivel = response.data.student.student_enrollment[0].nivel_id
                        this.student.grado = response.data.student.student_enrollment[0].grado_id
                        this.student.seccion = response.data.student.student_enrollment[0].seccion_id
                        this.student.enrollment_id = response.data.student.student_enrollment[0].id

                        this.requested = true
                    }
                }
            })
            .catch( (error) => {
                this.isLoadingCarnet = false
                console.log(error)
            })
        },
        getDropdownsData(){
            this.loadSede = true
            this.loadJornada = true
            this.loadLevel = true

            this.axios.get('/getdropdownsdata')
            .then( (response) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false

                this.sedes = response.data.sedes
                this.jornadas = response.data.jornadas
                this.levels = response.data.niveles
            })
            .catch( (error) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                console.log(error)
            })
        },
        getGrades(){
            this.loadGrade = true

            this.axios.get('/getgrades/'+this.student.nivel)
            .then( (response) => {
                this.loadGrade = false

                this.grades = response.data.grados
            })
            .catch( (error) => {
                this.loadGrade = false
                console.log(error)
            })
        },
        getSections(){
            if(this.student.nivel != '' && this.student.grado != ''){
                this.loadSection = true

                let grades = this.grades.filter((grade) => {
                    return grade.id == this.student.grado
                });
                this.sections = grades[0].sections
                this.loadSection = false
            }
        }
    },
    watch: {
        "student.id": function(){
            if(this.student.id == null){
                this.student.id = null
                this.student.enrollment_id = null
                this.student.nombre_alumno = ''
                this.student.sede = ''
                this.student.jornada = ''
                this.student.nivel = ''
                this.student.grado = ''
                this.student.seccion = ''
                this.$v.$reset()
            }
        },
        "student.nivel": function(){
            if(!this.requested){
                this.student.grado = ''
                this.student.seccion = ''
                this.$v.$reset()
            }

            if(this.student.nivel != ''){
                this.requested = false
                this.getGrades()
            }
        },
        "student.grado": function(){
            if(this.student.grado != '' && this.grades.length > 0){
                this.sections = []
                this.getSections()
            }
        },
        "grades": function(){
            this.sections = []
            this.getSections()
        }
    },
    created(){
        this.setLayout('main-layout')
        this.getDropdownsData()
        this.student.school_cycle = parseInt(this.moment().format("Y"))
    }
}
</script>

<style lang="css" scoped>
</style>
